.treble-item:nth-of-type(1) {
animation: slideRight 1s ease-out 1;
}

.treble-item:nth-of-type(2) {
animation: slideBottom 1s ease-out 1;
}

.treble-item:nth-of-type(3) {
animation: slideLeft 1s ease-out 1;
}

.treble-item {}
