.blog-content {
  line-height: 2rem;
}

.blog-content p {
  padding: 1rem 0;
}

.blog-content h1, .blog-content h2, .blog-content h3, .blog-content h4, 
.blog-content h5, .blog-content h6 {
  font-family: 'Neutro';
  margin: 2rem 0;
}

.blog-content a {
  text-decoration: underline;
}

.blog-content h2 {
  font-size: 1.5rem;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}

.blog-content h3 {
  font-size: 1rem;
  margin-top: 1rem;
  margin-bottom: 0.25rem;
}

.blog-content h4 {
  font-size: 0.75rem;
  margin-top: 0.75rem;
  margin-bottom: 0.125rem;
}

.blog-content h5, .blog-content h6 {
  font-size: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.125rem;
}

.blog-content ul, .blog-content ul {
  list-style-type: circle;
  margin-left: 2rem;
}

.blog-content li {
  margin: 1rem 0;
}

.fade-in {
  animation: fadeIn 1s linear 1;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100
  }
}

.slide-left {
  animation: slideLeft 1s ease-out 1;
}

@keyframes slideLeft {
  0% {
    transform: translateX(30px);
  }
  100% {
    transform: translateX(0px);
  }
}

.slide-right {
  animation: slideRight 1s ease-out 1;
}

@keyframes slideRight {
  0% {
    transform: translateX(-30px);
  }
  100% {
    transform: translateX(0px);
  }
}

.slide-bottom {
  animation: slideBottom 1s ease-out 1;
}

@keyframes slideBottom {
  0% {
    transform: translateY(30px);
  }
  100% {
    transform: translateY(0px);
  }
}

.slide-top {
  animation: slideTop 1s ease-out 1;
}

@keyframes slideTop {
  0% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0px);
  }
}
