@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Saira";
  src: local("Saira Thin"), url("./assets/fonts/Saira.woff") format("woff");
}

@font-face {
  font-family: 'Neutro';
  src: local('Neutro Extra Bold'), url("./assets/fonts/Neutro.woff") format('woff');
}

@font-face {
  font-family: 'Saira Condensed';
  src: local('Saira Condensed'), url("./assets/fonts/SairaCondensed-Medium.woff") format('woff');
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans'), url("./assets/fonts/OpenSans-Light.woff") format('woff');
}

.ff-64a34acc6b32d23b41f6064a {
  background: #0e5259 !important;
  border-top: 1px solid #5c1a4a !important;
}

.ff-64a34acc6b32d23b41f6064a__title {
  font-family: 'Neutro' !important;
  font-size: 2.5rem !important;
  color: #fff !important;
}

.ff-64a34acc6b32d23b41f6064a__subtitle {
  color: #fff !important;
}

.ff-64a34acc6b32d23b41f6064a__preference-title {
  color: #fff !important;
}

.fd-form-group input {
  border: solid 1px #fff !important;
}

.fd-form-group label {
  color: #fff !important;
}

.fd-form-check__label {
  color: #fff !important;
}