.hero-slide {
  animation: fadeIn 1s linear 1;
}


@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100
  }
}

.slider-dot {
  user-select: none;
}

.slider-dot:nth-of-type(1) {
    animation: slideBottomOne 1s ease-out 1;
    animation-delay: 0s;
}

.slider-dot:nth-of-type(2) {
    animation: slideBottomTwo 1s ease-out 1;
    animation-delay: 0s;
}

.slider-dot:nth-of-type(3) {
    animation: slideBottomThree 1s ease-out 1;
    animation-delay: 0s;
}

.slider-dot:nth-of-type(4) {
    animation: slideBottomFour 1s ease-out 1;
    animation-delay: 0s;
}

.slider-dot:nth-of-type(5) {
    animation: slideBottomFive 1s ease-out 1;
    animation-delay: 0s;
}

@keyframes slideBottomOne {
  0% {
    transform: translateY(30px);
  }
  20% {
    transform: translateY(0px);
  }
}

@keyframes slideBottomTwo {
  20% {
    transform: translateY(30px);
  }
  40% {
    transform: translateY(0px);
  }
}

@keyframes slideBottomThree {
  40% {
    transform: translateY(30px);
  }
  60% {
    transform: translateY(0px);
  }
}

@keyframes slideBottomFour {
  60% {
    transform: translateY(30px);
  }
  80% {
    transform: translateY(0px);
  }
}

@keyframes slideBottomFive {
  80% {
    transform: translateY(30px);
  }
  100% {
    transform: translateY(0px);
  }
}
